

document.addEventListener('DOMContentLoaded', function(){
	var galBtns = document.querySelectorAll('[data-gallery-clickable]');

	if (!galBtns.length){
		return;
	}

	var galleryThumbsSwiper,
			galleryTopSwiper;

	function thumbsSliderInit(slider, activeNumber){
		
		galleryThumbsSwiper = new Swiper(slider.querySelector('.swiper-container'),{
			spaceBetween: 5,
			slidesPerView: 'auto',
			freeMode: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true
		});

		slider.classList.add('inited');
	}

	function mainSliderInit(slider, activeNumber){

		var thumbsName = slider.dataset.id,
				bAutoHeight = true;

		if (slider.closest('.popup-gallery').classList.contains('popup-gallery--static-fixed')) {
			bAutoHeight = false;
		}

		galleryTopSwiper = new Swiper(slider, {
			slidesPerView: 1,
			navigation: {
				nextEl: slider.querySelector('.swiper-button-next'),
				prevEl: slider.querySelector('.swiper-button-prev')
			},
			thumbs: {
				swiper: galleryThumbsSwiper
			},
			keyboard: true,
			initialSlide: activeNumber,
			autoHeight: bAutoHeight,
			on: {
				slideChange: function(){
					// document.querySelector('[data-gallery-active-index]').textContent = this.activeIndex+1;	
				}
			}
		});

		slider.classList.add('inited');
	}

	function sliderInit(activeNumber){

		var galleryTop = document.querySelector('.gallery-top:not(.inited)'),
				galleryThumb = document.querySelector('.gallery-thumbs:not(.inited)');

		if (galleryThumb){
			thumbsSliderInit(galleryThumb, activeNumber);
		}

		// Инициализируем основные слайдеры
		mainSliderInit(galleryTop, activeNumber);

		// Закрытие по нажатию на esc
		function closeGallery(){
			document.querySelector('.popup-gallery.active').classList.remove('active');
			document.body.style.overflow = '';
		}

		// Закрытие по esc
		document.addEventListener('keyup', function(e) {
			if( e.keyCode === 27 ) {
				closeGallery();
				return false;
			}
		});

		// зыкрытие по клику по крестику
		document.addEventListener('click', function(e){
			var closeBtn = e.target.closest('.gallery-close-btn');
			if (closeBtn) {
				closeGallery();
			}
		});

		// ресайз при изменении размера окна
		window.addEventListener('resize', function(){
			if (galleryTop){
				galleryTopSwiper.update();
			}
			if (galleryThumb){
				galleryThumbsSwiper.update();
			}
		});

		document.body.style.overflow = 'hidden';
	}

	// Подгрузка галереи
	galBtns.forEach(function(btn){
		btn.addEventListener('click', function(){
			var link = this.dataset.href,
					target = this.dataset.target,
					targetEl = document.getElementById(target);
			
			if (targetEl){
				// Такая галерея уже загружалась ранее, поэтому только открываем её
				targetEl.classList.add('active');
				galleryTopSwiper.slideTo(btn.dataset.number);
				galleryTopSwiper.update();
				document.body.style.overflow = 'hidden';
			} else {
				// Грузим первый раз
				var request = new XMLHttpRequest();
				request.open('post', link, true);

				request.onreadystatechange = function () {
					if (this.readyState != 4) return;

					document.body.insertAdjacentHTML('beforeend', this.response);
					sliderInit(btn.dataset.number);					
				};

				request.onerror = function () {
					console.error('loadSlider: connection error');
				};

				request.send();
			}

		});
	});  
});